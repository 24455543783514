@import "_variables";
@import "_colors";
@import "_theme";
@import "../node_modules/bootstrap/scss/_functions.scss";
@import "../node_modules/bootstrap/scss/mixins/_hover.scss";

hr {
  border-top: 1px solid #919191aa;
}

input:disabled, button:disabled, input:read-only, .disabled {
  cursor: not-allowed;
}

input[type="checkbox"], input[type="radio"], select, .form-check, .form-check-input, .form-check-label {
  cursor: pointer;
}

.input-group-append button, .input-group-prepend button {
  background-color: darken($input-group-addon-bg, 3%);
}

.navbar {
  &.navbar-dark {
    background-color: $navbar-dark-bg;
  }
  &.navbar-light {
    background-color: $navbar-light-bg;
  }
}

// This is necessary because bootstrap hardcodes the #theme-color-level() second formal argument, leading to color inconsistencies when .alert is modified.
@each $color, $value in $theme-colors {
  .list-group-item-#{$color} {
    color: theme-color-level($color, $list-group-color-level);
    background-color: theme-color-level($color, $list-group-bg-level);

    &.list-group-item-action {
      @include hover-focus() {
        color: $color;
        background-color: darken(theme-color-level($color, $list-group-bg-level), 5%);
      }

      &.active {
        color: $white;
        background-color: $color;
        border-color: $color;
      }
    }
  }
}
